<script setup>
const props = defineProps({
  filter: Object
})
const { $toast } = useNuxtApp();
const {autocomplete, reverseGeocode} = useGoogleMaps();
const {slugify, searchLocationFilter} = useLocationData();

const handleClick = () => {  
  hideAll();
  toastState.value = true;
  toast.value.show();
}
const toast = ref();

const hideAll = () => {
  const toastElList = [].slice.call(document.querySelectorAll('.toast'))
  const toastList = toastElList.map(function (toastEl) {
    return new $toast(toastEl)
  })
  toastList.forEach(toast => toast.hide())
}

onMounted(() => {
  const toastEl = document.getElementById(props.filter.type)
  toast.value = new $toast(toastEl);

  toastEl.addEventListener('hidden.bs.toast', function () {
    toastState.value = false;
    handleClose();
  })
  
})

const align = computed(() => {
  return {
    'right-0': props.filter.order == 3 || props.filter.order == 'last',
    'left-0': props.filter.order == 1,
    'right-50': props.filter.order == 2
  }
})

const toastState = ref(false);
const button = computed(() => {
  return {
    // 'me-3': props.filter.type !== 'fcp-location',
    'open': toastState.value,
    'btn-location': props.filter.type === 'fcp-location'
  }
})

const emit = defineEmits(['onSelection'])

const search = ref(props.filter.search)
const predictions = ref([])
const selection = ref(props.filter.value);
// We cloned the original value 
let clonedValues = [...props.filter.value]

const handleAutocomplete = async (event) => {
  // showAutocompleteDropdown.value = true;  
  if (event.code === 'Space') {
    event.preventDefault()
    event.stopPropagation()
  }
  else {
    const {data, pending, error} = await autocomplete(event.target.value)
    if (data.value.statusCode === 200) {
      predictions.value.length = 0;
      const results = data.value.body.predictions;
      if (!toastState.value) {
        handleClick();
      }
      results.forEach((place) => {
        predictions.value.push({
          id: place.place_id,
          description: place.description.slice(0, 30) + (place.description.length > 30 ? '...' : '')
        })
      })
    }
    search.value = event.target.value;
  }  
}


const handlePredictionSelection = async (description) => {
  search.value = description;
  const {data: locations, error} = await searchLocationFilter(search.value, 250);  
  const locationIds = locations.value.data.map((location) => {
    return location.pulseId;
  })
  hideAll()
  props.filter.search = description;
  emit('onSelection', {
    filter: props.filter,
    value: locationIds
  });
}

const handleCurrentLocation = () => {
  if (process.client) {
    navigator.geolocation.getCurrentPosition(async (position) => {
      // Get the current location name
      const {data: result, error: responseError} = await reverseGeocode(`${position.coords.latitude}, ${position.coords.longitude}`);
      const firstResult = result.value.body.results.length > 0 ? result.value.body.results[0] : null;
      if (firstResult) {
        search.value = firstResult.formatted_address;
        props.filter.search = search.value;
        const {data: locations, error} = await searchLocationFilter(search.value, 250);  
        const locationIds = locations.value.data.map((location) => {
          return location.pulseId;
        })
        emit('onSelection', {
          filter: props.filter,
          value: locationIds
        });
      }
      else {
        // Show some predefined error message
      }
      hideAll()
    }, (error) => {
      // Show some predefined error message
      hideAll()
    })
  }
}

const options = ref(props.filter.options)
const checkOptions = (option) => {
  const checked = event.target.checked;
  if (checked) {
    if (option.includes(props.filter.all)) {
      selection.value = [props.filter.all]
      checkboxes.value.forEach(checkbox => {
        checkbox.reset();
      })
    }
    else {
      const position = selection.value.indexOf(props.filter.all);
      selection.value.push(option)
      if (position >= 0) {
        selection.value.splice(position, 1);
        checkboxes.value.forEach(checkbox => {
          checkbox.notAll();
        })
      }
    }
    
  } else {
    const position = selection.value.indexOf(option);
    selection.value.splice(position, 1);
  }
}

const handleSelection = () => {
  if (selection.value.length == 0) {
    selection.value = [props.filter.all]
    checkboxes.value.forEach(checkbox => {
      checkbox.reset();
    })
  }
  emit('onSelection', {
    filter: props.filter,
    value: selection.value
  });

  // Updating the clonedValues
  clonedValues = [];
  for (const v of selection.value) {
    clonedValues.push(v)
  }
  hideAll()
}

const checkboxes = ref([]);
const handleReset = () => {
  selection.value = [props.filter.all]
  checkboxes.value.forEach(checkbox => {
    checkbox.reset();
  })
  handleSelection()
  hideAll()
}

const isSelected = (option) => {
  return selection.value.includes(option)
}

const valueString = computed(() => {
  if (props.filter.type === 'fcp-age') {
    const result = selection.value.filter((option) => option !== '');
    return result.map((option) => {
      return option.replace('Ages ', '');
    }).join(', ')
  }
  else if (props.filter.type === 'fcp-interest') {
    const result = selection.value.filter((option) => option !== '');
    result.value = result.join(', ');
    return result.value.length >= 15 ? result.value.substring(0, 15) + '...' : result.value;
  }
})

const handleFocus = (event) => {
  if (event.target.value == '') {
    search.value = event.target.value;
    props.filter.search = event.target.value
    emit('onSelection', {
      filter: props.filter,
      value: props.filter.value
    });
  }
}

const handleClose = () => {
  selection.value.splice(0, selection.value.length)
  for (const value of clonedValues) {
    selection.value.push(value)
  }

  checkboxes.value.forEach(checkbox => {
    if (clonedValues.includes(checkbox.getLabel())) {
      checkbox.check()
    }
    else {
      checkbox.uncheck()
    }
  })
}

</script>

<template>
  <button type="button" class="filter-btn btn btn-light rounded-pill text-start border-0" :class="button" @click="handleClick" @keyup="handleActiveState">
    <label class="title">{{ filter.title }}</label>
    <br/>
    <label class="values" v-if="filter.type !== 'fcp-location'">{{ valueString }}</label>
    <input class="form-control border-0 p-0" type="text" :placeholder="filter.placeholder" :aria-label="filter.placeholder" v-if="filter.type === 'fcp-location'" @keyup="handleAutocomplete" :value="search" @focusout="handleFocus">
  </button>

  <div :id="filter.type" class="toast align-items-center hide border-0 p-2" :class="align" role="alert" aria-live="assertive" aria-atomic="true"  v-if="filter.type === 'fcp-location'" data-bs-autohide="false">
    <div class="toast-header justify-content-between">      
      <span>Select location</span>
      <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close" @click="handleClose"></button>
    </div>
    <div class="toast-body p-2 overflow-hidden">
      <button id="current-location-button  " class="location-suggestions-item text-start ps-3 py-2 mb-2 w-100" @click="handleCurrentLocation">
        <label>Current location</label>
      </button>
      <button class="location-suggestions-item   text-start ps-3 py-2 mb-2 w-100" v-for="place in predictions" :key="place.id" @click="handlePredictionSelection(place.description)"> 
        <label class="location-suggestions-item-text">{{ place.description }}</label> 
      </button>
    </div>
  </div>

  <div :id="filter.type" class="toast align-items-center hide border-0 p-2" :class="align" role="alert" aria-live="assertive" aria-atomic="true" data-bs-autohide="false" v-if="filter.type !== 'fcp-location'">
    <div class="toast-header justify-content-between">      
      <span>{{ filter.title }}</span>      
      <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close" @click="handleClose"></button>
    </div>
    <div class="toast-body">
      <div class="btn-group" role="group" aria-label="Basic checkbox toggle button group">
        <FilterCardProductCheckbox ref="checkboxes" v-for="option in options" :key="`btncheck-${slugify(option)}`" :id="`btncheck-${slugify(option)}`" :checked="isSelected(option)" :label="option" @onUpdate="checkOptions(option)"  />
      </div>
      <div class="actions d-grid gap-3 mt-3">
        <button class="btn btn-primary" @click="handleSelection" type="button">Apply</button>
        
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.btn-group .btn.filter-btn:active {
  background-color: white;
}
.filter-btn {
  width: 211px;

  &:last-of-type {
    margin-right: 0px !important;
    &::after {
      content: none;
    }
  }

  &::after {
    content: '';
    width: 1px;
    height: 30px;
    background-color: #d9d9d9;
    display: block;
    position: absolute;
    top: 15px;
    right: -2px;
  }

  &.open,
  &:hover {
    background-color: white;
    outline: 0.5px solid white;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);        
  }
  
  .title {
    font-size: 12px;
    font-weight: 600;
    color: $color-primary;
  }
  .values {
    font-weight: normal;
    color: $color-primary;
  }
  input.form-control {
    background-color: transparent;        
  }

  &.btn-location {
    width: 250px;
  }
}



.toast {
  position: absolute;
  z-index: 1;
  top: 70px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.16);
  background-color: white;
  border-radius: 12px;
  max-width: 320px;

  &.left-0 {
    left: 0;
  }
  &.right-0 {
    right: 0;
  }
  &.right-50 {
    right: 180px;
  }

  .toast-header {
    font-size: 20px;
    font-weight: 600;
    color: $color-primary;
    button {
      font-size: 17px;
      color: $color-primary;
    }
  }

  .toast-body {
    .location-suggestions-item {
      font-size: 16px;
      border: none;
      background-color: transparent ;
      &:focus-visible {
        outline-color: #a6a6a6;
        outline-width: 3px;
      }
    }
    .actions {
      .btn-outline-primary {
        border: 1px solid $color-light-gray;
        background-color: white;
        &:hover {
          border-color: $color-primary;
        }        
      }
    }
    .btn-group {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
    }
  }
}
</style>