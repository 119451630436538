<script setup>

const props = defineProps({
  data: Object,
  refs: Array
})

const splides = ref([])
const removedCards = ref([])

const ageFilter = ref({
  title: props.data.filterCardProducts.ageTitle,
  value: ['All ages'],
  type: 'fcp-age',
  all: 'All ages',
  order: 1,
  options: [
    'All ages',
    'Ages 7-9',
    'Ages 10-12',
    'Ages 13-19'
  ]
})

const interestFilter = ref({
  title: props.data.filterCardProducts.interestTitle,
  value: ['All topics'],
  order: 2,
  all: 'All topics',
  type: 'fcp-interest',
  options: [
    'All topics',
    'Coding',
    'GameDev',
    'Creative',
    'Robotics'
  ]
})

if (props.data.filterCardProducts.optionalTopics !== null && props.data.filterCardProducts.optionalTopics !== undefined) {  
  interestFilter.value.options = interestFilter.value.options.concat(props.data.filterCardProducts.optionalTopics);  
}

const locationFilter = ref({
  title: props.data.filterCardProducts.locationTitle,
  mobileTitle: props.data.filterCardProducts.locationTitleMobile,
  placeholder: props.data.filterCardProducts.locationPlaceholder,
  mobilePlaceholder: props.data.filterCardProducts.locationPlaceholderMobile,
  value: [],
  showPointer: true,
  order: 3,
  type: 'fcp-location',
  search: ''
})

const cardProducts = ref([])
const total = ref(0)


const slugify = (string) => {
  return string.toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');
}

const handleSelection = (selection) => {
  switch (selection.filter.type) {
    case 'fcp-location':
      locationFilter.value.value = selection.value;
      break;
    case 'fcp-interest':
      interestFilter.value.value = selection.value;
      break;
    default:
      ageFilter.value.value = selection.value;
      break;
  }

  // Filtering
  let totalItems = 0 ;
  
  // Re-adding the removed and recalculating
  for (const card of removedCards.value) {
    card.slide.classList.remove('remove');
    splides.value[card.splide].splide.add(card.slide, card.position);
  }
  removedCards.value = [];
  
  for (const [index, slider] of splides.value.entries()) {
    const currentSplide = slider.splide;
    
    for (let i = 0; i < slider.splide.length; i++) {
      const cardProduct = slider.splide.Components.Elements.slides[i];

      let interestValid = false, ageValid = false, locationValid = true;
      if (!interestFilter.value.value.includes('All topics')) {
        for (let i = 0; i < interestFilter.value.value.length; i++) {
          if (cardProduct.classList.value.includes(interestFilter.value.value[i])) {
            interestValid = true;
            break;
          }
        }
      }
      else {
        interestValid = true;
      }
      
      if (!ageFilter.value.value.includes('All ages')) {
        for (let i = 0; i < ageFilter.value.value.length; i++) {
          if (cardProduct.classList.value.includes(slugify(ageFilter.value.value[i]))) {
            ageValid = true;
            break;
          }
        }
      }
      else {
        ageValid = true;
      }
      
      if (cardProduct.classList.value.includes('ipc') && locationFilter.value.search !== '') {
        locationValid = false;
        for (let i = 0; i < locationFilter.value.value.length; i++) {
          if (cardProduct.classList.value.includes(locationFilter.value.value[i])) {
            locationValid = true;
            break;
          }
        }
      }

      if (!ageValid || !interestValid || !locationValid) {
        cardProduct.classList.add('remove');
        removedCards.value.push({
          'splide': index,
          'slide': cardProduct,
          'position': i
        });
      }
      else {
        totalItems++;
      }
    }
    
  }

  // Removing all the slides
  for (const card of removedCards.value) {
    splides.value[card.splide].splide.remove('.remove');
  }

  // Hiding sections and setting sliders to initial position after filtering
  for (const slider of splides.value) {
    if (slider.splide.length > 0) {
      slider.splide.root.parentElement.parentElement.parentElement.parentElement.parentElement.classList.remove('d-none')
      slider.splide.go(0);
    }
    else {
      slider.splide.root.parentElement.parentElement.parentElement.parentElement.parentElement.classList.add('d-none')
    }
    slider.splide.refresh()
  }

  total.value = totalItems;
  scrollToFilter()
  updateScrollEvent()
}

const { $offcanvas } = useNuxtApp();
const bsOffcanvas = ref();
const cardProductSections = ref(0)
const windowWidth = ref(0)

const handleClick = () => {
  bsOffcanvas.value.show();
}

const observer = ref({})
const observerCardProducts = ref({})
const sticky = ref(false)
const lastCardProducts = ref(true)

const observeFilter = () => {
  const filterSection = document.getElementById('filter-card-product');
  let sectionAbove = filterSection.parentElement.previousElementSibling
  
  if (sectionAbove == null) {
    sectionAbove = filterSection.parentElement.parentElement.previousElementSibling;
  }
  
  const list = document.getElementsByClassName("card-product")
  
  observer.value = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      sticky.value = !entry.isIntersecting && entry.boundingClientRect.top <= 0 && lastCardProducts.value
    })
  });
  
  if (sectionAbove) {
    observer.value.observe(sectionAbove);
  }
  else {
    sticky.value = false;
  }
}

onUpdated(() => {
  
});

const updateScrollEvent = () => {
  const list = document.getElementsByClassName("card-product")
  // Getting the last visible section
  let lastCardProductSection = list[list.length - 1].parentElement;
  for (let i = list.length - 1; i >= 0; i--) {
    if (!list[i].parentElement.classList.contains('d-none')) {
      lastCardProductSection = list[i].parentElement;
      break;
    }
  }

  const lastPosition = lastCardProductSection.offsetTop + 300;

  // Initializong/updating onscroll event
  window.onscroll = () => {
    if (window.pageYOffset >= lastPosition) {
      lastCardProducts.value = false
    }
    else {
      lastCardProducts.value = true
    } 
  }
}

onMounted(() => {
  const myOffcanvas = document.getElementById('mobileCourseFilter')
  bsOffcanvas.value = new $offcanvas(myOffcanvas)
  
  // Checking if the promo banner is present
  promoBanner.value = document.getElementById('promo-banner')

  myOffcanvas.addEventListener('hidden.bs.offcanvas', function () {
    // Reset to initial state
    
  })

  updateScrollEvent()
  
  // Initializing the splides array once they are all mounted
  for (const ref of props.refs) {
    if (ref.splide) {
      splides.value.push(ref.splide)
      if (ref.splide.splide) {
        total.value += ref.splide.splide.length;
      }
    }
  }

  if (total.value == 0) {
    cardProducts.value = document.getElementsByClassName('card-product-item');
    total.value = cardProducts.value.length;
  }

  windowWidth.value = window.innerWidth
  window.addEventListener('resize', function () {
    windowWidth.value = window.innerWidth
    updateScrollEvent()
  })

  observeFilter();
})

const location = ref();
const age = ref();
const interest = ref();
const promoBanner = ref(true);


const applySelection = () => {
  if (age.value) {
    age.value.handleSelection();
  }

  if (interest.value) {
    interest.value.handleSelection();
  }
  
  bsOffcanvas.value.hide();
}

const handleMobileReset = () => {
  if (location.value) {
    location.value.reset();
    locationFilter.value.search = '';
  }

  if (age.value) {
    age.value.reset();
    age.value.handleSelection();
  }

  if (interest.value) {
    interest.value.reset();
    interest.value.handleSelection();
  }
}

const resultsMessage = computed(() => {
  return total.value > 0 ? `${total.value} ${props.data.filterCardProducts.resultsText}` : props.data.filterCardProducts.noResultsText;
})

const scrollToFilter = () => {
  const element = document.getElementById("filter-card-product");
  
  const mobileOffset = windowWidth.value < 768 ? -5 : 0
  const offset = (promoBanner.value ? 103 : 68) + mobileOffset;

  window.scrollTo({
    behavior: 'smooth',
    top:
      document.getElementById("filter-card-product").parentElement.getBoundingClientRect().top -
      document.body.getBoundingClientRect().top -
      offset,
  })

  if (windowWidth.value < 768) {
    bsOffcanvas.value.show();
  }
}

const colorTheme = computed(() => {
  return {
    'white-theme': props.data.filterCardProducts.colorTheme === 'whiteFilter'
  }
})

const handleMobileClose = () => {
  age.value.handleClose();
  interest.value.handleClose();
}

const filterClass = computed(() => {
  return {
    'sticky': sticky.value && lastCardProducts.value,
    'no-promo-banner': !promoBanner.value
  }
})
</script>

<template>
  
    <div class="block filter-card-product" :class="filterClass" id="filter-card-product">
      <div class="container">
        <!-- <div class="row">
          <div class="col-12 text-center d-md-block">
            <button type="button" class="btn floating-filter btn-light rounded-pill border-0 py-4 px-6 position-fixed" :class="floatingClass"  @click="scrollToFilter">
              <label class="title">Filter by age or interest</label>              
            </button>
          </div>
        </div> -->
        <div class="row heading-row" v-if="data.showTitle">
          <div class="col-12 justify-content-center">
            <UIHeading :titleComponent="data.filterTitle" :titleClass="'text-center mb-3'" />
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-12 text-center d-none d-md-block">
            <div class="btn-group rounded-pill border-1 " role="group" aria-label="Filter card products" :class="colorTheme">
              <FilterCardProductButton :filter="ageFilter" @onSelection="handleSelection" v-if="data.filterCardProducts.enableAges" />
              <FilterCardProductButton :filter="interestFilter" @onSelection="handleSelection" v-if="data.filterCardProducts.enableTopics" />
              <FilterCardProductButton :filter="locationFilter" @onSelection="handleSelection" v-if="data.filterCardProducts.enableLocations" />
            </div>
          </div>
          <div class="col-12 text-center d-md-none">
            <button type="button" class="btn mobile-filter btn-light rounded-pill border-0 py-4 px-6"  @click="handleClick">
              <label class="title">Filter by age or interest</label>              
            </button>
          </div>
          <div class="offcanvas offcanvas-bottom  w-100 h-100 d-md-none p-0" id="mobileCourseFilter" tabindex="-1"  aria-labelledby="offcanvasLabel">
            <div class="offcanvas-header">
              <h2 class="offcanvas-title" id="offcanvasLabel">Course filters</h2>
              <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class="offcanvas-body">
              <FilterCardProductMobileFilter ref="age" :filter="ageFilter" @onSelection="handleSelection" v-if="data.filterCardProducts.enableAges" />
              <FilterCardProductMobileFilter ref="interest" :filter="interestFilter" @onSelection="handleSelection" v-if="data.filterCardProducts.enableTopics" />
              <FilterCardProductMobileFilter ref="location" :filter="locationFilter" @onSelection="handleSelection" v-if="data.filterCardProducts.enableLocations" />
              <div class="actions d-grid gap-3 mt-3">
                <button class="btn btn-primary" @click="applySelection" type="button">Apply</button>
                <button class="btn btn-outline-primary" @click="handleMobileReset" type="button">Reset</button>
              </div>
            </div>
          </div>
          
          <div class="col-12 text-center results-row">
            <div class="mt-3 fw-bold">{{ resultsMessage }}</div>
          </div>
        </div>
      </div>
    </div>
  
  
  
</template>

<style lang="scss" scoped>
#filter-card-product {
  &.sticky {
    position: fixed;
    width: 100%;
    padding: 10px 0px;
    top: 98px;
    z-index: 200;
    background-color: $color-secondary;
    border-bottom: 1px solid $color-accent;

    &.no-promo-banner {
      top: 63px;
    }
    .heading-row,
    .results-row {
      display: none;
    }

    .btn-group {
      background-color: $color-accent;
    }

    @include media-breakpoint-down(md) {
      background-color: transparent;
      bottom: 20px;
      top: unset;
      z-index: 1020;
      border-bottom: 0px;
      &.no-promo-banner {
        top: unset;
      }
    }

    &:has(.offcanvas.show) {
      z-index: 1032;
    }
  }
}
.filter-card-product {
  
  label {
    color: $color-primary;
  }
  .btn-group {
    // background-color: $color-accent;
    border-color: #d9d9d9;
    background-color: $color-accent;

    &.white-theme {
      background-color: $color-secondary;
    }

    &:has(.filter-btn.open),
    &:has(.filter-btn:hover) {
      background-color: $color-accent;
      &.white-theme {
        background-color: #bfbfbf;
      }
    }
  }

  .floating-filter {
    position: fixed;
    bottom: 40px;
    z-index: 1000;
    margin: 0 auto;
    left: 0;
    right: 0;
    max-width: 270px;
    background-color: $white;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  }

  .mobile-filter {
    background-color: white;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  }
}

#mobileCourseFilter {

  h2, label {
    color: $color-primary;
  }
  .offcanvas-header {
    box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.16);
  }

  .offcanvas-body {
    flex-grow: 0;
    overflow-x: hidden;
    .btn-group {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
    }
  }
}
</style>